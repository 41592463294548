.tableauhistory {
  table {
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border-bottom: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr {
    height: 40px;
    background-color: #ffffff;
    &.selected {
      background: #efefef;
    }
  }

  .disabled {
    background-color: #f2f2f2;
  }

  .active {
    background: #efefef 0% 0% no-repeat padding-box;
    opacity: 1;
    text-align: left;
    letter-spacing: 0px;
    font-family:Calibri, sans-serif;
    font-size: 1.125rem;
    font-weight: bold;
  }

  .chkbox {
    height: 38px;
  }

  ///Datepicker

  .selectPicker__menu {
    width: 700px;
  }

  .selectPicker__menu-list {
    overflow-y: visible;
  }

  .selectPicker__option,
  .selectPicker__option.selectPicker__option--is-focused {
    background-color: #0a3c5c;
    color: #000;
  }

  .react-daterange-picker__wrapper {
    display: flex;
    flex-wrap: nowrap;
  }
}

.exportHistory {
  table {
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border-bottom: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  tr {
    height: 40px;
    background-color: #ffffff;
    &.selected {
      background: #efefef;
    }
  }

  .disabled {
    background-color: #f2f2f2;
  }

  .active {
    background: #efefef 0% 0% no-repeat padding-box;
    opacity: 1;
    text-align: left;
    font-family: Verdana;
    font-weight: bold;
    font-size: 1.125rem;
    letter-spacing: 0px;
  }

  .chkbox {
    height: 38px;
  }

  ///Datepicker

  .selectPicker__menu {
    width: 700px;
  }

  .selectPicker__menu-list {
    overflow-y: visible;
  }

  .selectPicker__option,
  .selectPicker__option.selectPicker__option--is-focused {
    background-color: #0a3c5c;
    color: #000;
  }

  .react-daterange-picker__wrapper {
    display: flex;
    flex-wrap: nowrap;
  }
}
