.Label-Week {
  text-align: left;
  font-family:Calibri, sans-serif;  
  letter-spacing: 1px;
  color: #0a3c5c;
  opacity: 1;
  font-size: 16px;
}

.mt-2rem {
  margin-top: 2rem;
}

.img-alert {
  height: 107px;
  width: 129px;
}

.btn-img {
  height: 107px;
  width: 129px;

  &.dirtywater{
    background-size: contain;
    background-image: url('../../assets/eaux usees-01.png');
    &:hover{
      filter: saturate(3);
    }
  }

  &.environnement{
    background-size: contain;
    background-image: url('../../assets/environnement-01.png');
    &:hover{
      filter: saturate(3);
    }
  }
  
  &.performance{
    background-size: contain;
    background-image: url('../../assets/progres-01.png');
    &:hover{      
      filter: saturate(3);
    }
  }
}

.arrow-btn-img {
  position: absolute;
  top: 47px;
}

.widget {
  height: 210px;
  //min-width: 18.5rem;
  

  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #00000014;
  border-radius: 6px;
  opacity: 1;

  &.placehold {
    height: 125px;
  }

  & > .placehold-title {
    font-family: Verdana;
    letter-spacing: 0.85px;
    color: #b4b6b5;
    text-transform: uppercase;
    opacity: 1;
    font-size: 14px;
    font-weight: bold;
  }

  & .widget-title {
    text-align: left;
    font-family: Verdana;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.9px;
    color: #b4b6b5;
    text-transform: uppercase;
    opacity: 1;
    margin:0;
  }

  & .widget-unit {
    margin: 0px;
    float: right;
    font-size: 1.125rem;
    text-align: right;
    font-family: Verdana;
    font-weight: bold;
    letter-spacing: 0px;
    color: #0a3c5c;
    opacity: 1;
  }
}

.home-btn {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #00000014;
  border-radius: 6px;
  opacity: 1;
}

.home-btn-alert {
  border: 1px solid #f24c07;
  font-family: Verdana;
  letter-spacing: 0px;
  color: #f24c07;
  opacity: 1;
  font-size: 1rem;
   
  & > div{
    white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  }

  &:hover {
    background: #f24c071f;
    color: #0a3c5c;
    border: none;
  }

}

.home-btn-warning {
  color: 1px solid #f24c07;
}

.home-btn-info {
 
  font-family:Calibri, sans-serif;
  font-weight: bold;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 1.125rem;

  & .info-title {
    font-family:Calibri, sans-serif;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 0.9px;
    color: #b4b6b5;
    text-transform: uppercase;
    opacity: 1;
  }

}

.home-btn-plus {
  background: #FFCD0019 0% 0% no-repeat padding-box;
  border: 1px solid #FFCD00;
  color: #FFCD00;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  justify-content: center;
  width: 30px;
  font-size: 28px;
  line-height: 28px;






}

.home-text {
  
  font-size: 1.125rem;
  font-family: Verdana;
  letter-spacing: 0px;
  color: #0a3c5c;
  opacity: 1;

  &:hover {
    background: #FFCD00;
    color: #0a3c5c;
    border: none;
  }

  &:hover .home-btn-plus {
    border: 2px solid #fff;
    color: #fff;
  }

}

.btn-go {
  min-height: 46px;
}

.backgroundwave{
  background-image: url("../../assets/background.png"), linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 30%, rgba(242,245,247,1) 60%, rgba(242,245,247,1) 100%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  width: 100%;
}

.close-btn {
  font-family: Avenir, sans-serif;
  letter-spacing: 0px;
  color: #0a3c5c;
  opacity: 1;
  font-size: 1.438rem;
  font-weight: bold;
  background: url('../../assets/icon-close.svg');
  height: 38px;
  width: 38px;

  &:hover{
    background: url('../../assets/icon-close_survol.svg');
  }
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: none;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: none;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.alert-text {
  font-family: Verdana;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.9px;
  color: #b4b6b5;
  text-transform: uppercase;
  opacity: 1;
}