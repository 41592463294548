// Styles
// CoreUI Icons Set
@import "~@coreui/icons/css/coreui-icons.css";
// Import Flag Icons Set
//@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import "~font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
//@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import "./../scss/style.scss";

.app {
  font-family: Verdana;
}

//boutons communs
.close-btn {
  font-family: Avenir, sans-serif;
  letter-spacing: 0px;
  color: #0a3c5c;
  opacity: 1;
  font-size: 1.438rem;
  font-weight: bold;
  background: url("./../assets/icon-close.svg");
  height: 38px;
  width: 38px;

  &:hover {
    background: url("./../assets/icon-close_survol.svg");
  }
}

.btn-primary, .btn-outline-primary {
  min-width: 203px;
}

//modale
.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: none;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: none;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

//customisation du bouton d'importation de fichier
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "";
  display: none;
}

.input-group-text {
  background-color: #fff;
}

.firstUpper {
  &:first-letter {
    text-transform: uppercase;
  }
}