.Label-Widget
{
    font-family: Calibri, sans-serif ;
    letter-spacing: 1px;
    color: #B4B6B5;
    text-transform: uppercase;
    opacity: 1;
    margin-bottom: 15px;
    font-size: 18px;
    font-weight: bold;
}


.Label-Granularite
{
    font-family: Calibri, sans-serif ;
    letter-spacing: 0px;
    color: #0a3c5c;
    opacity: 1;
    font-size: 28px;
    font-weight: bold;
}