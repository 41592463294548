.tableaudoc {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  //border-collapse: collapse;
  width: 100%;

  border: 2px solid #b4b6b5;
  margin-bottom: 20px;

  tr {
    height: 18px;
    padding: 0px;
    //border: 1px solid #b4b6b5;

    &.selected {
      background: #efefef;
    }
  }

  & td {
    border: 2px solid #b4b6b5;
    padding: 0px 10px;
  }

  & td:first-child,
  & th:first-child {
    border: 2px solid #b4b6b5;
    width: 44px;
    height: 44px;
    padding: 0px;
    margin: 0px auto;
    text-align: center;
  }

  th {
    padding: 0px 10px;
    padding-top: 12px;
    padding-bottom: 12px;
    border-left: 2px solid #b4b6b5;
    border-right: 2px solid #b4b6b5;
    color: #0a3c5c;
    font-family: Verdana;
    letter-spacing: 0px;
    font-size: 1rem;
  }

  & th:first-child {
    border-top: none;
    text-align: center;
    margin: 0px auto;
  }

  .disabled {
    background-color: #f2f2f2;
  }

  .active {
    background: #efefef 0% 0% no-repeat padding-box;
    opacity: 1;
    text-align: left;
    letter-spacing: 0px;
    font-family:Calibri,sans-serif;
    font-size: 1.125rem;
    font-weight: bold;
  }
}

.tab-content {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #00000014;
  border-radius: 10px;
  opacity: 1;
  border: none;
}

.doc-title {
  display: "flex";
  align-items: "center";
}

.template-title {
  text-align: left;
  letter-spacing: 0px;
  color: #FFCD00;
  opacity: 1;
  font-family: Verdana;
  font-weight: bold;
  font-size: 1.375rem;
}

#deleteItem {
  font-size: 1.375rem;
  text-align: center;
  letter-spacing: 0px;
  color: #454545;
  opacity: 1;
}


#importform{
  label, #importFilelabel{
    border-color: #B4B6B5;
  }

  .form-control{
    border-color: #B4B6B5;
    border-radius: 0;
  }

  & > ::-webkit-file-upload-button{
    border: none;
    background: white;
  }

}

#document{
  .btn:not([disabled]):hover{
    color: orange;
    }
}

#importform .form-control.error-field{
  border: 2px solid red;
}

.error-text{
  color: red;
}
