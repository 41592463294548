.tableaudoctemplate {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  //border-collapse: collapse;
  width: 100%;

  //border: 1px solid #b4b6b5;
  margin-bottom: 20px;

  tr {
    height: 18px;
    padding: 0px;
    //border: 1px solid #b4b6b5;
  }

  & td,
  & th {
    border-bottom: 1px solid #b4b6b5;
    width: 44px;
    height: 44px;
    padding: 0px;
    margin: 0px auto;
    text-align: center;
  }


  th {
    padding-top: 12px;
    padding-bottom: 12px;
    color: #0a3c5c;
    font-family: Verdana;
    letter-spacing: 0px;
    font-size: 1rem;
  }

  td:first-child,
  th:first-child {
    text-align: start;
    padding-left: 20px;
  }
  td:last-child {
    text-align: end;
    padding-right: 20px;
  }

  .disabled {
    background-color: #f2f2f2;
  }

  .active {
    background: #efefef 0% 0% no-repeat padding-box;
    opacity: 1;
    text-align: left;
    font-family: Verdana;
    font-weight: bold;
    font-size: 1.125rem;
    letter-spacing: 0px;
  }

  .link {
    text-align: left;
    text-decoration: underline;
    font-family: Verdana;
    font-size: 0.938rem;
    letter-spacing: 0px;
    color: #FFCD00;
    opacity: 1;
  }
}

#template {
  .btn-outline-primary {
    font-size: 16px;
    font-weight: bold;
    font-family: Verdana;
  }


.btn:not([disabled]):hover{
color: orange;
}

}
