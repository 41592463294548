.header-fixed .app-header{
    height: 70px;
}

a:not([href]):not([tabindex])
{
    width: 250px;
    height: 70px;
}

.site-title-parenthese
{
   background-size: contain;
   background-repeat: no-repeat;
   width: 10px;
}

.right
{
    background-image: url("../../assets/saurParentheseRight.png");
    margin-left: 10px;
}

.left
{
    background-image: url("../../assets/saurParentheseLeft.png");
    margin-right: 10px;
}

.app-header .navbar-brand
{
    background-color: #FFCD00;
}

.brand-minimized .app-header .navbar-brand
{
    background-color: #FFCD00;
}

@media (max-width: 991.98px)
{
    .app-header .navbar-brand 
    {
        background-color:white;
    }
    a:not([href]):not([tabindex])
    {
        width: 60px;
        height: 70px;
    }
}