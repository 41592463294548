.btn-pill {
    width: 92px;
    color: #FFCD00;
    border-color:#FFCD00;
    background-color:white;
    margin-right: 16px;
    font-size: 12px;
    font-weight: 800;
}

.btn-pill:hover 
{
    background-color:#FFCD00;
    color: #0a3c5c;
    border-color:#FFCD00;
}


.btn-pill:active 
{
    background-color:#FFCD00;
    color: #0a3c5c;
    border-color:#FFCD00;
}

.btn-pill:target 
{
    background-color:#FFCD00;
    color: #0a3c5c;
    border-color:#FFCD00;
}

.btn-pill:focus 
{
    background-color:#FFCD00;
    color: #0a3c5c;
    border-color:#FFCD00;
}

.btn-selected 
{
    background-color:#FFCD00;
    color: #0a3c5c;
    border-color:#FFCD00;
}

.button-icon
{
    background: white;
    height: 32px;
    width: 40px;
    color: #FFCD00;
    border-color:#FFCD00;
}

.left-button
{
    border-radius: 4px 0px 0px 4px;
}

.right-button
{
    border-radius:  0px 4px 4px 0px;
}


.button-icon:focus 
{
    background-color:#FFCD00;
    color: #0a3c5c;
    border-color:#FFCD00;
}

.button-icon:hover 
{
    background-color:#FFCD00;
    color: #0a3c5c;
    border-color:#FFCD00;
}