.tableau {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;

  border: 1px solid #b4b6b5;
  margin-bottom: 20px;

  & td,
  & th {
    border: 1px solid #b4b6b5;
    width: 115px;
    height: 48px;
    padding: 0px;
  }

  & td .cellx:hover:not(.disabled) {
    background-color: #ddd;
    cursor: pointer;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;

    text-align: center;
    color: #0a3c5c;
    font-family: Verdana;
    letter-spacing: 0px;
    font-size: 1rem;
  }

  & th:first-child {
    padding-left: 10px;
    text-align: left;
  }

  .disabled {
    background-color: #f2f2f2;
  }

  .active {
    background: #f5e7d3 0% 0% no-repeat padding-box;
    opacity: 1;
    text-align: left;
    font-family: Verdana;
    font-size: 1.125rem;
    font-weight: bold;
    letter-spacing: 0px;
    color: #FFCD00;
  }

  .ind {
    font-size: 0.8rem;
  }
}

.info-label {
  text-align: left;
  font-family: Verdana;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 1rem;
}

#collectInfo {
  .nav-item {
    background-color: #f0ecec;
    text-align: left;
    font-family: Verdana;
    letter-spacing: 0px;
    color: #0a3c5c;
    opacity: 1;
    font-size: 14px;
    font-weight: normal;
    box-shadow: 8px -8px 20px #00000014;

    &:not(:nth-last-child(2)) .nav-link span {
      border-right: 1px solid;
    }

    &:not(:first-child) .nav-link.active {
      position: relative;
      left: -2px;
    }

    & .nav-link.active span {
      border: none;
    }

    & .nav-link.active {
      border: none;
    }
  }

  .tab-content {
    border-top-left-radius: 0px;
    box-shadow: 0px 0px 20px #00000014;
  }

  .period-label {
    display: flex;
    text-align: left;
    font-family: Verdana;
    letter-spacing: 0px;
    color: #FFCD00;
    opacity: 1;
    font-size: 1.25rem;
    font-weight: bold;
    justify-content: center;
    align-items: center;
  }
}

.table-text {
  color : #0a3c5c;
}

.bin-btn {
  font-family: Avenir, sans-serif;
  letter-spacing: 0px;
  color: #0a3c5c;
  opacity: 1;
  font-size: 1.438rem;
  font-weight: bold;
  background: url("../../assets/bin.svg") no-repeat;
  height: 24px;
  width: 24px;
}

.edit-btn {
  font-family: Avenir, sans-serif;
  letter-spacing: 0px;
  color: #0a3c5c;
  opacity: 1;
  font-size: 1.438rem;
  font-weight: bold;
  background: url("../../assets/edit.svg") no-repeat;
  height: 24px;
  width: 24px;
}

.btn-primary {
  color: #0a3c5c;
}

// .close-btn {
//   font-family: Avenir;
//   letter-spacing: 0px;
//   color: #0a3c5c;
//   opacity: 1;
//   font-size: 1.438rem;
//   font-weight: bold;
//   background: url("../../assets/icon-close.svg");
//   height: 38px;
//   width: 38px;

//   &:hover {
//     background: url("../../assets/icon-close_survol.svg");
//   }

// }

// .modal-header {
//   display: flex;
//   align-items: flex-start;
//   justify-content: space-between;
//   padding: 1rem 1rem;
//   border-bottom: none;
//   border-top-left-radius: 0.3rem;
//   border-top-right-radius: 0.3rem;
// }

// .modal-footer {
//   display: flex;
//   align-items: center;
//   justify-content: flex-end;
//   padding: 1rem;
//   border-top: none;
//   border-bottom-right-radius: 0.3rem;
//   border-bottom-left-radius: 0.3rem;
// }

.edit-input {
  border: none;
  border-bottom: 1px solid #0a3c5c;
  width: 100%;
  text-align: left;
  font-family: Verdana;
  letter-spacing: 0px;
  color: #FFCD00;
  opacity: 1;
  font-weight: bold;
  font-size: 1.25rem;
}

.edit-label {
  display: flex;
  text-align: left;
  font-family: Verdana;
  letter-spacing: 0px;
  color: #0a3c5c;
  opacity: 1;
  font-weight: bold;
  font-size: 1.25rem;
  margin: 0 28px 36px 28px;
}

.display-label {
  text-align: left;
  font-family: Verdana;
  letter-spacing: 0px;
  color: #0a3c5c;
  opacity: 1;
  font-weight: bold;
  font-size: 1.75rem;
}

.sel-input {
  height: 35px;
  border: 1px solid #b4b6b5;
}

.sel-select {
  height: 35px;
  border: 1px solid #b4b6b5;
}

.error-field {
  border-bottom: 2px solid red;
}

.error-text {
  color: red;
}

.tab-pane {
  min-height: 400px;
}
