#RangeDatePicker {
  ///Datepicker
  .selectPicker__control {
   // width: 305px;
 
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  .selectPicker__value-container.selectPicker__value-container--has-value {
    height: 45px;
    cursor: pointer;
  }

  .selectPicker__menu {
    width: 700px;
    z-index: 3;
  }

  .selectPicker__menu-list {
    overflow-y: visible;
  }

  .selectPicker__option,
  .selectPicker__option.selectPicker__option--is-focused {
    background-color: white;
    color: #0a3c5c;
  }

  .react-daterange-picker__wrapper {
    display: flex;
    flex-wrap: nowrap;
    color: #0a3c5c;
  }

  .selectPicker__indicator.selectPicker__dropdown-indicator {
    display: none;
  }

  .selectPicker__indicator-separator {
    display: none;
  }

  .selectPicker__placeholder {
    color: #0a3c5c;
    label {
      cursor: pointer;
    }
  }
}
