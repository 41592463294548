body {
  margin: 0;
  font-family: 'Verdana';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  color: #0a3c5c;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.main{
  background-color: #f2f5f7;
  display: flex;
}