.sidebar
{
    background-color: #FFCD00;
    width: 250px;
    color: #0a3c5c;
    position: relative;
}

// html:not([dir="rtl"]) .sidebar
// {
//     width: 250px;
// }
.saur-banner
{
    position:absolute;
    bottom: 0px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.saur-banner-img
{
    width: 100px;
}

.sidebar .nav 
{
    width: 250px; 
}

.sidebar .sidebar-nav
{
    width: 250px;
    margin-top: 18px;
}

.sidebar .nav-link.active
{
   background-color: #00000034;
}

.sidebar .nav-link .nav-icon
{
    color: #0a3c5c;
    font-size: 18px;
    margin-right: 15px;
}

.sidebar .nav-link 
{
    color: #0a3c5c;
    font-weight: 800;
}

.sidebar .nav-item
{
    font-size: 18px;
    width: 250px;
    color: #0a3c5c;
}

.sidebar .sidebar-minimizer
{
    background-color: #FFCD00;
}

.sidebar-minimized .sidebar .sidebar-minimizer
{
    background-color: #FFCD00;
}

.sidebar-minimized .nav-link-minimizer:hover
{
    background: #FFCD00;
}

.sidebar .nav-link:hover {
    color: #fff;
    background: #00000034;
}

.sidebar .nav-link.active .nav-icon {
    color: #0a3c5c;
}

/* .main{
    background-color: #FFCD000D;
} */

html:not([dir="rtl"]) .sidebar {
    margin-left: -250px;
    width: 250px;
}

.header-fixed .app-body{
    margin-top: 70px;
}

.sidebar-fixed .app-header + .app-body .sidebar {
    height: calc(100vh - 70px);
}

#navDropdown + ul{
    overflow-y: auto;
    height: calc(48vh - 9.375rem);
    overflow-x: hidden;
}

@media (min-width: 576px)
{
    html:not([dir="rtl"]) 
    .sidebar-sm-show.sidebar-fixed 
    .main, html:not([dir="rtl"]) 
    .sidebar-sm-show.sidebar-fixed 
    .app-footer, html:not([dir="rtl"]) 
    .sidebar-show.sidebar-fixed 
    .main, html:not([dir="rtl"]) 
    .sidebar-show.sidebar-fixed 
    .app-footer {
        margin-left: 250px;
    }
}

@media (min-width: 992px)
{
    html:not([dir="rtl"]) 
    .sidebar-lg-show.sidebar-fixed 
    .main, html:not([dir="rtl"]) 
    .sidebar-lg-show.sidebar-fixed 
    .app-footer, html:not([dir="rtl"]) 
    .sidebar-show.sidebar-fixed 
    .main, html:not([dir="rtl"]) 
    .sidebar-show.sidebar-fixed 
    .app-footer {
        margin-left: 250px;
    }
}

@media (min-width: 768px)
{
    html:not([dir="rtl"]) 
    .sidebar-md-show.sidebar-fixed 
    .main, html:not([dir="rtl"]) 
    .sidebar-md-show.sidebar-fixed 
    .app-footer, html:not([dir="rtl"]) 
    .sidebar-show.sidebar-fixed 
    .main, html:not([dir="rtl"]) 
    .sidebar-show.sidebar-fixed 
    .app-footer {
        margin-left: 250px;
    }
}

@media (min-width: 992px)
{
    .sidebar-minimized 
    .sidebar 
    .nav-item:hover > .nav-link 
    {
        background: #FFCD00;   
    }
}

.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
    background-color: transparent;
    opacity: 0.9;
}


.sublink {
    text-align: left;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    font-size: 1rem;
    font-family: Verdana;
  }
