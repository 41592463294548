 .Spinner
 {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    color: #FFCD00;
 }

 .switch-secondary .switch-input:checked + .switch-slider  {
    background-color: #FFCD00;
 }