.group-1 {
  background: #e5e5e5 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #00000014;
  border-radius: 6px;
  opacity: 1;
  margin-bottom: 24px;
}

.group-2 {
  background: #b4b6b5 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #00000014;
  border-radius: 6px;
  opacity: 1;
}
.group-3 {
  background: #d0d0d0 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #00000014;
  border-radius: 6px;
  opacity: 1;
  margin-bottom: 29px;
}

.group-4 {
  background: #b4b6b5 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #00000015;
  border-radius: 6px;
  opacity: 1;
  margin-bottom: 29px;
}

.group-5 {
  background: #d0d0d0 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 20px #00000015;
  border-radius: 6px;
  opacity: 1;
}

#menu {
  label {
    text-align: left;
    letter-spacing: 0px;
    color: #0a3c5c;
    opacity: 1;
    font-size: 1.5rem;
  }

  button {
    background-color: #fff;
    font-size: 16px;
    font-family: Verdana;
    margin-bottom: 24px;
    border-radius: 10px;

    &.btn-outline-primary:hover {
      color: #fff;
      background-color: #FFCD00;
      border-color: #FFCD00;
    }
  }

  .group-3 {
    button {
      margin-bottom: 38px;
    }
  }

  .group-5 {
    button:last-child {
      margin-bottom: 33px;
    }
  }

}
