.containerBorder {
    border: 1px solid #FFCD00;
    border-radius: 6px;
    opacity: 1;
    //padding: 10px;
   // display: inline-grid;
    //width: 32%;
    //margin-left: 16px;
    height: 270px
}

.ProductTitle {
    font-size: 18px;
    letter-spacing: 0.36px;
    color: #0a3c5c;
    text-transform: uppercase;
    opacity: 1;
}

.InformationsDiv {
    display: flex;
    justify-content: center;
    height: 220px
}

.QuantityDiv {
   display: inline-grid;
    width: 50%
}

.QuantityTitle {
    color: #0a3c5c;
    opacity: 1;
    font-size: 16px;
}

.QualityTitle {
    color: #0a3c5c;
    opacity: 1;
    font-size: 16px;
}

.QuantityTitleDiv { 
    justify-content: center;
    display: flex;
    height: 30px;
}

.QualityTitleDiv { 
    justify-content: center;
    display: flex;
    height: 30px;
}

.QuantityInformationDiv { 
    justify-content: center;
    display: contents; 
    height: 160px;
}

.QualityInformationDiv{ 
    justify-content: center;
    display: flex; 
    height: 160px;
}

.CircularProgressbarWithChildrenDiv{
    height: 140px;
    width: 140px;
}

.SeparationBorder{
    align-self:center;
    height: 150px; 
    border-left: 1px solid #FFCD00;
    display: flex 
}

.PourcentageQuality{ 
    font-size: 35px; 
    display: flex; 
    justify-content: center;
    align-items: center;
    color: #FFCD00; 
}

.QuantityInformation { 
    font-size: 35px;
    color: #FFCD00;
    opacity: 1;
    justify-content: center;
    display: flex;
}

.QuantityInformationComparison { 
    font-size: 18px;
    margin-top: -50px;
    color: #5D5D5D;
    justify-content: center;
    display: flex;
}

.PourcentageQualityComparaison { 
    font-size: 1.25rem; 
    display: flex;
    justify-content: center;
    margin-top: -25px;
    color: #5D5D5D; 
    }


/* @media (min-width: 1200px)
{
.container {
    max-width: 100%;
}
} */

.CircularProgressbar .CircularProgressbar-path {
    stroke: #FFCD00;
}

.CircularProgressbar .CircularProgressbar-background {
    fill: #FFCD000D;
}

.CircularProgressbarWithChildren {
    background: #FFCD000D;
}

.CircularProgressbar .CircularProgressbar-trail {
    stroke: transparent;
}

.CircularProgressbar .CircularProgressbar-text {
    fill: #FFCD00;
    font-size: 25px;
}